import React from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';

const Wrap = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;

  a {
    color: currentColor;
  }
`;

const StyledCheckbox = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
`;

const Checkmark = styled.span`
  position: relative;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-top: 2px;
  margin-right: 1em;
  border: 2px solid ${colors.darkerGray};
  flex-shrink: 0;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 5%;
    width: 100%;
    height: 100%;
    background: url('images/svg/checkmark.svg') no-repeat 50% / 80% transparent;
    opacity: 0;
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  line-height: 1.4375;

  ${StyledCheckbox}:checked + ${Checkmark}::after {
    opacity: 1;
  }
`;

type CheckboxType = {
  required?: boolean;
  label?: React.ReactElement
}

const Checkbox = (props: CheckboxType) => {
  const { required } = props;

  return (
    <Wrap>
      <Label>
        <StyledCheckbox required={required} type='checkbox' />
        <Checkmark />
        {props.label}
      </Label>
    </Wrap>
  );
}

export default Checkbox;
