export const PORTAL = 'https://notio.ai'

export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';

export const PASSWORD_FORGOT = '/forgot-password';
export const PASSWORD_RESET = '/reset-password';

export const ACCOUNT = '/account';
export const ACCOUNT_RIDES = `${ACCOUNT}/rides`;
export const ACCOUNT_SETTINGS = `${ACCOUNT}/settings`;
export const ACCOUNT_PASSWORD = `${ACCOUNT}/password`;
export const ACCOUNT_PURCHASES = `${ACCOUNT}/purchases`;
export const APP_DOWNLOAD = `${ACCOUNT}/golden-cheetah`;

export const CONTACT_US = '#';
export const FAQ = '#';
export const PRIVACY = '#';
export const TERMS = '#';
