import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { FirebaseContext } from 'components/Firebase';
import { Session } from 'utils';

interface Props {
  exact?: boolean,
  component: any,
  path: string,
  redirectTo: string
}

const ProtectedRoute = (props: Props) => {
  const firebase = useContext(FirebaseContext);
  const user = firebase.user;
  const fbToken = user.token;
  const sessionToken = Session.getToken();

  // Redirect if there's no token set in Firebase or Session Storage
  if (fbToken === '' && sessionToken === '') {
    return (
      <Redirect to={props.redirectTo} />
    )
  }

  return (
    <Route path={props.path} component={props.component} />
  );
}

export default ProtectedRoute;
