import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import Input, { InputErrorType } from 'components/Input';
import Checkbox from 'components/Checkbox';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import * as mq from 'constants/mediaQueries';

import { FirebaseContext } from 'components/Firebase';

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrap = styled.div`
  width: 100%;
  max-width: 350px;
  padding-left: 0;
  padding-right: 0;

  ${mq.to(mq.device.mobileL)} {
    padding-left: 1em;
    padding-right: 1em;
  }
`;

const Footer = styled.footer`
  margin: 1.5em 0;
  text-align: center;

  span {
    display: block;
    margin-bottom: 0.3em;
  }

  a {
    color: inherit;
  }
`;

const Success = styled.footer`
  margin: 1.5em 0;
  text-align: center;

  span {
    display: block;
    margin-bottom: 2em;
  }
`;

const emptyError: InputErrorType = {
  code: '',
  message: ''
};

const SignUp: React.FC = () => {
  const [signedUp, setSignedUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(emptyError);
  const firebase = useContext(FirebaseContext);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError({
        code: 'confirm-password',
        message: 'Passwords must match!'
      });
    } else {
      setError(emptyError);

      firebase.signUp(email, password)
        .then(() => {
          setError(emptyError);
          setSignedUp(true);
        })
        .catch((error: InputErrorType) => {
          setError(error);
        });
    }
  };

  return (
    <Page footer={false}>
      <Form onSubmit={onSubmit}>
        <Wrap className='container-fluid'>

          {!signedUp && (
            <React.Fragment>
              <PageTitle>Sign Up</PageTitle>
              <Input
                type='email'
                placeholder='Email'
                required
                value={email}
                error={error}
                errorCodes={['auth/email-already-in-use', 'auth/invalid-email']}
                onChange={e => setEmail(e.currentTarget.value)}
              />

              <Input
                type='password'
                placeholder='Password'
                required
                value={password}
                error={error}
                errorCodes={['auth/weak-password']}
                onChange={e => setPassword(e.currentTarget.value)}
              />

              <Input
                type='password'
                placeholder='Confirm Password'
                required
                value={confirmPassword}
                error={error}
                errorCodes={['auth/weak-password', 'confirm-password']}
                onChange={e => setConfirmPassword(e.currentTarget.value)}
              />

              <Checkbox
                required
                label={(
                  <span>I agree to the <a href='https://notio.ai/pages/terms-of-use' rel='noreferrer noopener' target='_blank'>Terms of Use</a> and have read the <a href='https://notio.ai/pages/privacy-policy' rel='noreferrer noopener' target='_blank'>Privacy Policy</a>.</span>
                )}
              />

              <Button type='submit'>Sign Up</Button>
              <Footer>
                <span>Already have an account?</span>
                <Link to={routes.SIGN_IN}>Sign In</Link>
              </Footer>
            </React.Fragment>
          )}

          {signedUp && (
            <React.Fragment>
              <PageTitle>Success!</PageTitle>
              <Success>
                <span>You may use your credentials to sign in to your Notio account.</span>
                <Button to={routes.SIGN_IN}>Sign In</Button>
              </Success>
            </React.Fragment>
          )}

        </Wrap>
      </Form>
    </Page>
  );
}

export default SignUp;
