export default {
  black: '#000',
  white: '#fff',
  yellow: '#fff200',
  red: '#ff1744',
  green: '#4baf50',
  greenLight: '#dbefdc',
  darkerGray: '#2e2e2e',
  darkGray: '#525252',
  gray: '#929292',
  lightGray: '#c1c1c1',
  lighterGray: '#d8d8d8',
  lightestGray: '#f5f3f3',
  btn_hover_text: '#e9dd00',
};
