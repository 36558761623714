export const device = {
    mobileS: 320,
    mobile: 375,
    mobileL: 425,
    tabletXS: 560,
    tabletS: 640,
    tablet: 768,
    ipad: 1024,
};

export const from = (breakpoint: number) => {
    return `@media (min-width: ${breakpoint}px)`;
};

export const to = (breakpoint: number) => {
    return `@media (max-width: ${breakpoint - 1}px)`;
};

export const between = (from: number, to: number) => {
    return `@media (min-width: ${from}px) and (max-width: ${to - 1}px)`;
};
