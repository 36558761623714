import React, { useContext } from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';
import * as routes from 'constants/routes';
import { NavLink } from 'react-router-dom';

import { PageContext } from 'components/Page';
import { FirebaseContext } from 'components/Firebase';
import InlineSVG from 'components/InlineSVG';

interface WrapProps {
  show?: boolean,
  onClick?: () => void
}

const Menu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  overflow: auto;
  padding: 1.5em;
  background-color: ${colors.darkerGray};
  color: ${colors.yellow};
  transition: transform 0.3s ease;
  transform: translateX(-100%);
`;

const Wrap = styled.div<WrapProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: all 0.2s ease;

  ${props => props.show && `
    opacity: 1;
    visibility: visible;

    ${Menu} {
      transform: none;
    }
  `}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
  opacity: 0.7;
`;

const Close = styled.button`
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 0.3em;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  color: currentColor;
  font-size: 1.5em;
  outline: none;

  svg {
    display: block;
    width: 1em;
    height: 1em;
  }
`;

const Link = styled(NavLink)`
  display: block;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  color: currentColor;
  text-decoration: none;

  &.active {
    font-weight: 700;
  }
`;

const SignOut = styled.span`
  display: flex;
  align-items: center;
  margin-top: 2em;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: underline;

  svg {
    display: block;
    width: 1em;
    height: 1em;
    margin-right: 1em;
    margin-top: -1px;
  }
`;

const Offcanvas = () => {
  const app = useContext(PageContext);
  const firebase = useContext(FirebaseContext);

  return (
    <Wrap show={app.offcanvas}>
      <Overlay onClick={() => app.setOffcanvas(false)} />
      <Menu>
        <Close onClick={() => app.setOffcanvas(false)}>
          <InlineSVG icon='back' />
        </Close>

        <Link
          onClick={() => app.setOffcanvas(false)}
          to={routes.ACCOUNT_SETTINGS}
        >
          Account Settings
        </Link>
        <Link
          onClick={() => app.setOffcanvas(false)}
          to={routes.ACCOUNT_PASSWORD}
        >
          Password
        </Link>
        <Link
          onClick={() => app.setOffcanvas(false)}
          to={routes.ACCOUNT_RIDES}
        >
          My Rides
        </Link>
        <Link
          onClick={() => app.setOffcanvas(false)}
          to={routes.ACCOUNT_PURCHASES}
        >
          My Purchases
        </Link>
        <Link
          onClick={() => app.setOffcanvas(false)}
          to={routes.APP_DOWNLOAD}
        >
          Download Golden Cheetah
        </Link>

        <SignOut onClick={() => firebase.signOut()}>
          <InlineSVG icon='signout' />
          Sign Out
        </SignOut>
      </Menu>
    </Wrap>
  );
}

export default Offcanvas;
