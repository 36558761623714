import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import HeadTitle from 'components/HeadTitle';
import Button from 'components/Button';
import Input, { InputErrorType } from 'components/Input';
import SuccessMsg from 'components/Firebase/Success';
import { FirebaseContext } from 'components/Firebase';

const Form = styled.form``;

const Footer = styled.div`
  margin-top: 1.5em;
`;

const emptyError: InputErrorType = {
  code: '',
  message: ''
};

const AccountSettings: React.FC = () => {
  const firebase = useContext(FirebaseContext);
  const user = firebase.user.user;
  const userName = user.displayName ? user.displayName : '';
  const userMail = user.email;

  const [name, setName] = useState(userName);
  const [password, setPassword] = useState('');
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [email, setEmail] = useState(userMail);
  const [error, setError] = useState(emptyError);
  const [success, setSuccess] = useState(false);

  const updateEmail = () => {
    console.log('update email');
    firebase.updateEmail(email)
      .then(function() {
        setSuccess(true);
      }).catch((error: any) => {
        if (error.code === 'auth/requires-recent-login') {
          setPasswordRequired(true);
        }
        setError(error);
      })
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (userName !== name) {
      firebase.updateName(name)
        .then(() => {
          setError(emptyError);
          setSuccess(true);
        })
        .catch((error: InputErrorType) => {
          setError(error);
        });
    }

    if (userMail !== email) {

      if (passwordRequired) {
        firebase.reauthenticate(password)
          .then(() => {
            setError(emptyError);
            updateEmail();
          })
          .catch((error: InputErrorType) => {
            setError(error);
          });

        return;
      }

      updateEmail();
    }
  };

  return (
    <React.Fragment>
      <HeadTitle>Account Settings</HeadTitle>
      <Form onSubmit={onSubmit}>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Input
              type='text'
              placeholder='Display Name'
              required
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />
          </div>
            <div className='col-xs-12 col-sm-6'>
              <Input
                type='email'
                placeholder='Email'
                required
                value={email}
                error={error}
                errorCodes={['auth/email-already-in-use', 'auth/invalid-email']}
                onChange={e => setEmail(e.currentTarget.value)}
              />
            </div>
        </div>

        {passwordRequired && (
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
            <Input
              type='password'
              placeholder='Password'
              required
              value={password}
              error={error}
              errorCodes={['auth/requires-recent-login', 'auth/wrong-password']}
              onChange={e => setPassword(e.currentTarget.value)}
            />
            </div>
          </div>
        )}

        {success && (
          <SuccessMsg>You have successfully updated your profile!</SuccessMsg>
        )}

        <Footer className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Button type='submit'>Update Settings</Button>
          </div>
        </Footer>
      </Form>
    </React.Fragment>
  );
}

export default AccountSettings;
