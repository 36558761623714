import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';
import InlineSVG from 'components/InlineSVG';

type AccordionProps = {
  children: React.ReactElement | Array<React.ReactElement>;
}

const StyledAccordion = styled.div``;

export const Accordion = (props: AccordionProps) => {
  return (
    <StyledAccordion>{ props.children }</StyledAccordion>
  )
}

type PanelType = {
  title: string
  default_active: boolean
  children?: any
  
}

const StyledPanel = styled.div`
  background-color: ${colors.lightestGray};

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

type PanelTitleType = {
  active?: boolean
}

const StyledPanelTitle = styled.button<PanelTitleType>`
  display: flex;
  width: 100%;
  padding: 0.85rem 1.25rem;
  border: 0;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  background-color: ${colors.lightestGray};
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${colors.lighterGray};
  }

  svg {
    display: block;
    width: 1em;
    height: 1em;
    font-size: 1rem;
    transition: transform 0.3s ease;
  }

  ${props => props.active && `
    background-color: ${colors.lighterGray};

    svg {
      transform: rotateZ(180deg);
    }
  `}
`;

type PanelContentType = {
  ref: any
}

const StyledPanelContent = styled.div<PanelContentType>`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s ease-out;
`;

const StyledPanelContentInner = styled.div`
  padding: 1.5em;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  p, ul, ol {
    font-size: 14px;
    line-height: 1.4;
  }

  li {
    margin-top: 0.4em;
    margin-bottom: 0.4em;

    ul, ol {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }

  .important {
    padding: 1em;
    border: 3px solid;
    background-color: ${colors.yellow};
    word-break: break-word;

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Panel = (props: PanelType) => {
  const contentRef = useRef();
  const [active,setActive] = useState(props.default_active);

  useEffect(() => {
    if (contentRef.current) {
      const panelBody: HTMLElement | any = contentRef.current;

      if (!active) {
        panelBody.style.maxHeight = null
      } else {
        panelBody.style.maxHeight = panelBody.scrollHeight + 'px'
      }
    }

  }, [active]);

  return (
    <StyledPanel>
      <StyledPanelTitle
        onClick={() => { setActive(!active) }}
        active={active}
      >
        { props.title }
        <InlineSVG icon='arrow-down' />
      </StyledPanelTitle>

      <StyledPanelContent ref={contentRef}>
        <StyledPanelContentInner>
          { props.children }
        </StyledPanelContentInner>
      </StyledPanelContent>
    </StyledPanel>
  )
}
