import React from 'react';
import styled from 'styled-components';
import HeadTitle from 'components/HeadTitle';
import colors from 'constants/colors';
import * as mq from 'constants/mediaQueries';

const Note = styled.h3`
  margin-top: 3em;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  line-height: 1.4;

  ${mq.from(mq.device.ipad)} {
    margin-top: 5em;
  }
`;

const Link = styled.a`
  color: ${colors.black};
  font-weight: 700;
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.darkGray};
  }
`;

const Purchases: React.FC = () => {
  return (
    <React.Fragment>
      <HeadTitle>My Purchases</HeadTitle>
      <Note>
        We're working on getting you all the information here. <br/>
        For now, for more information on your purchases, please contact <Link href="mailto:support@notio.ai">support@notio.ai</Link>.
      </Note>
    </React.Fragment>
  );
}

export default Purchases;
