import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.25em;
  letter-spacing: 0.5px;
`;

const PageTitle = (props: any) => {
  return (
    <Title {...props}>{props.children}</Title>
  );
}

export default PageTitle;
