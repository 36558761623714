import React, { useEffect, useContext, useState } from 'react';
import Offcanvas from 'components/Offcanvas';
import Header from 'components/Header';
import Footer from 'components/Footer';
import styled from 'styled-components';

import { FirebaseContext } from 'components/Firebase';

const Wrap = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > * {
    width: 100%;
  }
`;

const Content = styled.div`
  flex-grow: 1;
`;

type Props = {
  children: React.ReactElement | Array<React.ReactElement>;
  footer?: boolean;
}

export const PageContext = React.createContext({} as any);

const Page: React.FC<Props> = (props) => {
  const firebase = useContext(FirebaseContext);
  const user = firebase.user;
  const token = user.token;

  useEffect(() => {
    document.body.style.overflow = '';
  }, [firebase]);

  const isLoggedIn = token.length;
  const [offcanvas, setOffcanvas] = useState(false);

  const provider = {
    isLoggedIn: isLoggedIn,
    offcanvas: offcanvas,
    setOffcanvas: (setTo: boolean) => {
      if (setTo) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
      setOffcanvas(setTo);
    }
  }

  const offcanvasNode = !isLoggedIn ? null : <Offcanvas />

  return (
    <PageContext.Provider value={provider}>
      <Wrap>
        <Header />
        <Content>
          { props.children }
        </Content>
        {props.footer !== false && (
          <Footer />
        )}
        {offcanvasNode}
      </Wrap>
    </PageContext.Provider>
  );
}

export default Page;
