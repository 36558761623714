import React from 'react';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';

const ResetPassword: React.FC = () => {
  return (
    <Page footer={false}>
      <PageTitle>Reset Your Password</PageTitle>
    </Page>
  );
}

export default ResetPassword;
