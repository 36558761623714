import React from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';

export type InputErrorType = {
  code: string;
  message: string;
}

type InputProps = {
  type: string;
  placeholder: string;
  rightPlaceholder?: React.ReactElement;
  required: boolean;
  value: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  hint?: string;
  error?: InputErrorType;
  errorCodes?: Array<string>;
  hasError?: boolean;
}

const Wrap = styled.div`
  width: 100%;
  margin: 0.5em 0;
  padding: 0.1em 0;
`;

const StyledInput = styled.input<InputProps>`
  width: 100%;
  padding: 0.75em 1em;
  border: 2px solid ${colors.lightGray};
  box-shadow: none;
  background-color: ${colors.white};
  outline: none;
  font-size: 1em;
  box-shadow: inset 0.1em 0.1em 0 0.1em rgba(0, 0, 0, 0.07);
  transition: all 0.2s ease;

  &:focus {
    border-color: ${colors.darkerGray};
    box-shadow: none;
  }

  ${props => props.hasError && `
    border-color: ${colors.red};
  `}
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.label`
  display: block;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  font-weight: 600;

  a {
    color: currentColor;
  }
`;

const Error = styled.span`
  display: block;
  margin-top: 0.5em;
  color: ${colors.red};
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.35px;
`;

const Hint = styled(Error as any)`
  color: ${colors.gray};
`;

const Input = (props: InputProps) => {

  // Loop through prop error codes and error object
  // To determine if the error message should be visible
  const { errorCodes, error } = props;
  let errorNode = null;
  let hasError = false;

  // If both errorCodes and error props exist
  if (errorCodes && error) {
    for (let i = 0; i < errorCodes.length; i++) {
      const code = errorCodes[i];
      if ((code === error.code) || (code === '*' && error.code)) {
        // Error code match
        hasError = true;

        // Set error node
        errorNode = (
          <Error>{error.message}</Error>
        );

        // Early exit
        break;
      }
    }
  }

  return (
    <Wrap>
      <Header>
        {props.placeholder && (
          <Label>{props.placeholder}</Label>
        )}
        {props.rightPlaceholder && (
          <Label>{props.rightPlaceholder}</Label>
        )}
      </Header>
      <StyledInput {...props} placeholder={''} hasError={hasError} />
      {props.hint && (
        <Hint>{props.hint}</Hint>
      )}
      {errorNode}
    </Wrap>
  );
}

export default Input;
