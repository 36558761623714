import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import Input, { InputErrorType } from 'components/Input';
import { Redirect, Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import * as mq from 'constants/mediaQueries';

import { FirebaseContext } from 'components/Firebase';

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrap = styled.div`
  width: 100%;
  max-width: 350px;
  padding-left: 0;
  padding-right: 0;

  ${mq.to(mq.device.mobileL)} {
    padding-left: 1em;
    padding-right: 1em;
  }
`;

const Footer = styled.footer`
  margin: 1.5em 0;
  text-align: center;

  span {
    display: block;
    margin-bottom: 0.3em;
  }

  a {
    color: inherit;
  }
`;

const emptyError: InputErrorType = {
  code: '',
  message: ''
};

const SignIn = () => {
  const firebase = useContext(FirebaseContext);
  const user = firebase.user;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(emptyError);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    firebase.signIn(email, password)
      .catch((error:InputErrorType) => {
        setError(error);
      });
  };

  // If User is signed in, redirect
  if (user.token) {
    return (
      <Redirect to={routes.ACCOUNT_RIDES} />
    )
  }

  return (
    <Page footer={false}>
      <Form onSubmit={onSubmit}>
        <Wrap className='container-fluid'>
          <PageTitle>Sign In</PageTitle>

          <Input
            type='email'
            placeholder='Email'
            required
            value={email}
            error={error}
            errorCodes={['auth/user-not-found', 'auth/invalid-email']}
            onChange={e => setEmail(e.currentTarget.value)}
          />

          <Input
            type='password'
            placeholder='Password'
            rightPlaceholder={(
              <Link to={routes.PASSWORD_FORGOT}>Forgot Password?</Link>
            )}
            required
            value={password}
            error={error}
            errorCodes={['auth/wrong-password']}
            onChange={e => setPassword(e.currentTarget.value)}
          />

          <Button type='submit'>Sign In</Button>

          <Footer>
            <span>Don't have an account?</span>
            <Link to={routes.SIGN_UP}>Sign up</Link>
          </Footer>
        </Wrap>
      </Form>
    </Page>
  );
}

export default SignIn;
