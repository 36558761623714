import React from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';

const Styled = styled.div`
  margin: 0.5em 0 1em;
  padding: 1em 1.3em;
  font-weight: 600;
  background-color: ${colors.greenLight};
  color: ${colors.green};
`;

const Success: React.FC = (props) => {
  return (
    <Styled>{props.children}</Styled>
  )
}

export default Success;
