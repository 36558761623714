import React from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';
import * as routes from 'constants/routes';
import * as mq from 'constants/mediaQueries';
import { Link } from 'react-router-dom';
import InlineSVG from 'components/InlineSVG';

const Wrap = styled.div`
  width: 100%;
  margin-top: 5em;
  padding: 1.5em 0;
  text-transform: uppercase;
  background-color: ${colors.darkerGray};
  color: ${colors.yellow};

  ${mq.from(mq.device.ipad)} {
    padding: 3.125em 0;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mq.to(mq.device.ipad)} {
    flex-direction: column;
  }
`;

const Logo = styled(Link)`
  width: 100%;
  width: 100%;
  max-width: 120px;

  ${mq.from(mq.device.ipad)} {
    max-width: 190px;
  }

  .icon--logo {
    display: block;
    width: 100%;
  }
`;

const Links = styled.div``;

const TopLink = styled.a`
  font-weight: 700;

  ${mq.from(mq.device.ipad)} {
    margin-left: 3em;
  }

  ${mq.to(mq.device.ipad)} {
    display: inline-block;
    margin: 1em 1em 0;
  }
`;

const Bottom = styled.div`
  margin-top: 1.5em;
  padding-top: 1.5em;
  padding-bottom: 1em;
  border-top: 2px solid ${colors.darkGray};

  ${mq.to(mq.device.ipad)} {
    text-align: center;
  }

  ${mq.from(mq.device.ipad)} {
    margin-top: 3em;
    padding-top: 3em;
  }

  span {
    font-size: 12px;
    font-weight: 700;
    opacity: 0.7;

    ${mq.to(mq.device.ipad)} {

      &:first-child {
        display: block;
        margin-bottom: 0.5em;
      }
    }
  }

  span:not(:first-child) {
    position: relative;
    margin-left: 1em;
    padding-left: 1em;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -1px;
      left: 0;
      border-radius: 50%;
      border: 1px solid;
    }

    ${mq.to(mq.device.ipad)} {
      &:not(:last-child) {
        padding-left: 0;
        margin-left: 0;

        &::before {
          content: none;
        }
      }
    }
  }
`;

const LinkWrap = styled.span`
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

const Footer: React.FC = () => {
  return (
    <Wrap>
      <div className='container-fluid'>
        <Top>
          <Logo to={routes.ACCOUNT_RIDES}>
            <InlineSVG icon='logo' />
          </Logo>
          <Links>
            <TopLink href='https://notio.ai/apps/help-center'>FAQ</TopLink>
            <TopLink href='https://notio.ai/pages/contact-us-desk'>Contact Us</TopLink>
          </Links>
        </Top>
        <Bottom>
          <span>Notio ® All Rights Reserved 2019</span>
          <LinkWrap>
            <a href='https://notio.ai/pages/privacy-policy'>Privacy Policy</a>
          </LinkWrap>
          <LinkWrap>
            <a href='https://notio.ai/pages/terms-of-use'>Terms of Use</a>
          </LinkWrap>
        </Bottom>
      </div>
    </Wrap>
  );
}

export default Footer;
