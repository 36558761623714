import React, { useContext } from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';
import * as routes from 'constants/routes';
import InlineSVG from 'components/InlineSVG';
import * as mq from 'constants/mediaQueries';
import { PageContext } from 'components/Page';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1em;
  padding: 0.6em 0;
  background-color: ${colors.darkerGray};

  ${mq.to(mq.device.tablet)} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    + * {
      margin-top: 80px;
    }
  }

  a {
    display: block;
    max-width: 100px;
    margin: 0.5em auto;
    color: ${colors.yellow};

    ${mq.from(mq.device.tablet)} {
      max-width: 190px;
    }
  }

  .icon--logo {
    width: 100%;
  }
`;

const HamburgerToggle = styled.button`
  position: absolute;
  top: 50%;
  left: 1em;
  transform: translateY(-50%);
  padding: 0;
  border: 0;
  background-color: transparent;
  color: ${colors.yellow};
  outline: none;
  cursor: pointer;

  ${mq.from(mq.device.tablet)} {
    display: none;
  }

  svg {
    width: 1.8em;
    height: 1.8em;

    ${mq.to(mq.device.tablet)} {
      width: 1.3em;
      height: 1.3em;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span,
  svg {
    display: block;
  }
`;

const Header = () => {
  const app = useContext(PageContext);

  const hamburger = !app.isLoggedIn ? null : (
    <HamburgerToggle onClick={() => app.setOffcanvas(true)}>
      <InlineSVG icon='hamburger' />
    </HamburgerToggle>
  );

  return (
    <Wrap>
      {hamburger}

      <a href={routes.PORTAL}>
        <InlineSVG icon='logo' />
      </a>
    </Wrap>
  );
}

export default Header;
