import React from 'react';
import styled from 'styled-components';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import { ACCOUNT_SETTINGS } from 'constants/routes';

const Footer = styled.footer`
  text-align: center;

  .container-fluid {
    max-width: 350px;
  }
`;

const Page404: React.FC = () => {
  return (
    <Page footer={false}>
      <PageTitle>Page not found</PageTitle>
      <Footer>
        <div className="container-fluid">
          <Button to={ACCOUNT_SETTINGS}>Go back</Button>
        </div>
      </Footer>
    </Page>
  );
}

export default Page404;
