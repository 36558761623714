import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import Input, { InputErrorType } from 'components/Input';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';

import { FirebaseContext } from 'components/Firebase';

const Title = styled(PageTitle)`
  margin-bottom: 0.5em;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.header`
  padding-bottom: 2em;
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: 1em;
  line-height: 1.4;
  font-weight: 600;
`;

const Wrap = styled.div`
  width: 100%;
`;

const FieldsWrap = styled.div`
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
`;

const Footer = styled.footer`
  margin: 2em 0;
  text-align: center;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 1em;
  color: currentColor;
`;

const EmailSent = styled.div`

  ${Footer} {
    margin-top: 0;
  }
`;

const emptyError: InputErrorType = {
  code: '',
  message: ''
};

const PasswordForgot = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(emptyError);
  const firebase = useContext(FirebaseContext);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    firebase.forgotPassword(email)
      .then(() => {
        setError(emptyError);
        setEmailSent(true);
      })
      .catch((error: InputErrorType) => {
        setError(error);
      });
  };

  return (
    <Page footer={false}>
      <Form onSubmit={onSubmit}>
        <Wrap className='container-fluid'>

          {!emailSent && (
            <React.Fragment>
              <Header className="container-fluid">
                <Title>Forgot Your Password?</Title>
                <Subtitle>We'll help you reset it and get back on track.</Subtitle>
              </Header>

              <FieldsWrap>
                <Input
                  type='email'
                  placeholder='Email'
                  required
                  value={email}
                  error={error}
                  errorCodes={['*']}
                  onChange={e => setEmail(e.currentTarget.value)}
                />

                <Footer>
                  <Button type='submit'>Reset Password</Button>
                  <StyledLink to={routes.SIGN_IN}>Sign In</StyledLink>
                </Footer>
              </FieldsWrap>
            </React.Fragment>
          )}

          {emailSent && (
            <EmailSent>
              <Header className="container-fluid">
                <Title>Password Recovery Email Sent!</Title>
                <Subtitle>Please check the inbox for { email } <br /> to find a link to reset your password.</Subtitle>
              </Header>

              <FieldsWrap>
                <Footer>
                  <Button to={routes.SIGN_IN}>Sign In</Button>
                  <StyledLink to='#'>Resend</StyledLink>
                </Footer>
              </FieldsWrap>
            </EmailSent>
          )}

        </Wrap>
      </Form>
    </Page>
  );
}

export default PasswordForgot;
