import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Session } from 'utils';
import Rides from './Rides';
import HeadTitle from 'components/HeadTitle';

const AccontRides: React.FC = () => {
  const [activities, setActivities] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const token = Session.getToken();

  useEffect(() => {
    if (!loaded) {
      axios.get(`${process.env.REACT_APP_NOTIO_API_URL}/activity/v1/activities`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          const resData = response.data.activities;
          const sanitized = sanitizeData(resData);
          setLoaded(true);
          setActivities(sanitized);
        })
        .catch(() => {
          setActivities([]);
          setLoaded(true);
        })
    }
  }, [loaded, token]);

  return (
    <React.Fragment>
      <HeadTitle>My Rides</HeadTitle>
      <Rides rides={activities} />
    </React.Fragment>
  );
}

export type SanitizedDataType = {
  id: string;
  group: string;
  distance: string;
  title: string;
  date: {
    formatted: string;
    year: number;
    timestamp: number;
    month: number;
    monthName: string;
    day: number;
  };
  duration: string
}

// Parse JSON data and prepare it for frontend
// Parse timestamps, calculate durations, etc
const sanitizeData: Function = (rides: Array<any>) => {
  const sanitized: Array<SanitizedDataType> = [];

  rides.forEach(ride => {
    const meta = ride.metadata;
    const title = meta.title ? meta.title : 'No title';
    const distance = parseFloat(meta.distanceInKm).toFixed(2);

    const startStamp = meta.editedStartTimestamp !== '0'
      ? meta.editedStartTimestamp
      : meta.startTimestamp;
    const endStamp = meta.endTimestamp;

    const startMoment = moment.unix(startStamp);
    const endMoment = moment.unix(endStamp);

    // Get ride date
    const rideDate = startMoment.format('YYYY.MM.DD');

    // Get ride duration
    const initialStart = moment.unix(meta.startTimestamp);
    const difference = moment(endMoment).diff(initialStart);
    const duration = moment.duration(difference);

    const rideDuration = Math.floor(duration.asHours()) + moment.utc(difference).format(':mm:ss');

    // Compile sanitized object
    sanitized.push({
      id: ride.id,
      group: `${startMoment.format('MMMM')} ${startMoment.year()}`,
      distance: distance,
      title: title,
      date: {
        timestamp: parseInt(startStamp, 10),
        formatted: rideDate,
        year: startMoment.year(),
        month: startMoment.month(),
        monthName: startMoment.format('MMMM'),
        day: startMoment.day() + 1 // since it's zero based =()
      },
      duration: rideDuration,
    });
  });

  return sanitized;
}

export default AccontRides;
