import React from 'react';
import HeadTitle from 'components/HeadTitle';
import Button from 'components/Button';
import { Accordion, Panel } from 'components/Accordion';
import styled from 'styled-components';

const Header = styled.header`
  padding-bottom: 1px;

  a {
    max-width: 157px;
    text-align: center;
  }

  p {
    margin-top: 0.5em;
    margin-bottom: 1.5em;
  }
`;

const ReleaseNotes = styled.div`

  > h4 {
    font-weight: 600; 
    text-transform: uppercase;
  }
`;

const Download: React.FC = () => {
  return (
    <React.Fragment>
      <HeadTitle>Download Golden Cheetah</HeadTitle>

      <Header>
        <Button href='https://storage.googleapis.com/gc-notio/GCNotio_V3.5_1.16.5.dmg'>MAC</Button>
        <p>We support MAC OS High Sierra 10.13 and up</p>

        <Button href='https://storage.googleapis.com/gc-notio/GCNotio_V3.5_1.16.5_64bit.exe'>Windows</Button>
        <p>We support Windows 10 v1809 and up</p>
      </Header>

	  <Header>
		<p>Source code: <a href="https://github.com/notio-technologies/GCNotio">GitHub </a></p>
	  </Header>
	  
      <ReleaseNotes>
        <h4>Release Notes</h4>


        <Accordion>
		<Panel title='V.1.16.5 - released November 8th 2021' default_active={true}>
            
			<strong>Improvements:</strong>
			<ul>
				<li>Add error messages for Find velodrome and Find Aero Test intervals.</li>
				<li>Update "About" box URLs and add GC Notio GitHub repository URL.</li>
			</ul>
			<strong>Bug fixes:</strong>
			<ul>
				<li>Fix issue with some derived metrics (like slope) weren't updated right away after a compute.</li>
				<li>Correct BCVX speed drops edit tool</li>
				<ul>
			
					<li>Fix a crash that might occurs trying to correct speed drops.</li>
					<li>Take also into account speed/cadence sensor status during process.</li>
			
				</ul>
			</ul>

          </Panel>
		
        <Panel title='V.1.16.1 - released January 6th 2021' default_active={false}>
            
            <p><strong>Improvements:</strong></p>

            <ul>
              <li>Computing process.</li>
              <ul>
                <li>Implement it as data processor.</li>
                <li>Add the possibility to activate the compute during ride importation.</li>
                <li>Compute data in 4 Hz. Some data are in still 1 Hz for retro compatibility.</li>
                <li>Optimize computation process.</li>
                <li>New progress status user interface.</li>
              </ul>
                
                
              <li>Optimize interval summary. (Sidebar and overlay)</li>
              <li>Remove "Edit" chart in the default layout.</li>
              <li>Improve Notio metrics using 4 Hz data.</li>
              <li>Minor bug fixes.</li>
              <li>Stability improvements.</li>
            </ul>

            <p><strong>Features:</strong></p>
            <ul>
              <li>Add ride file GC compatibility verification.</li>
              <li>Add new total energy Notio metrics.</li>
            </ul>

          </Panel>

        <Panel title='V.1.15.0 - released Septembre 16th 2020' default_active={false}>
            
            <div className='important'>
              <p><strong>Important</strong></p>
              <ul>This version brings the software up-to-date with the official release of GoldenCheetah V3.5. Once installed, it won't be possible to go back to a previous version. So, it is strongly advice to <a href="https://desk.zoho.com/portal/notio/en/kb/articles/how-to-back-up-your-athlete-profile">backup </a> your athletes' profile before updating.</ul>
            </div>

            <p><strong>Bugs Fixed:</strong></p>
            
            <ul>
              <li>Fix Strava connection.</li>
              <li>Minor bug fixes.</li>
            </ul>

            <p><strong>Improvements:</strong></p>
            
            <ul>
              <li>Reimplementation of Notio CSV file importation.</li>
              <li>Use local time in Notio Cloud sync dialog. (Notio Legacy Cloud still use UTC time)</li>
              <li>Update Help main menu.</li>
              <li>Stability improvements.</li>
            </ul>
          </Panel>

          <Panel title='V.1.14.0 - released June 30th 2020' default_active={false}>
            <p><strong>Known Issues:</strong></p>
        
            <ul>
              <li>Connecting to a Strava account is not possible.</li>
            </ul>
              
            <p><strong>Bugs Fixed:</strong></p>
            
            <ul>
              <li>Fix SSL connection issue on some Windows installations.</li>
            </ul>
            
            <p><strong>Features:</strong></p>
            
            <ul>
              <li>Add two new methods to the Find Intervals tool.
                <ul>
                  <li>BETA: Find velodrome intervals.</li>
                  <li>BETA: Find Notio aero test segments.</li>
                </ul>
              </li>
              
              <li>Add tool to manually offset speed to compensate sensor transmission delay.</li>
              <li>Enable high DPI scaling in Windows.</li>
            </ul>
            
            <p><strong>Improvements:</strong></p>
            
            <ul>
              <li>CdA Analysis chart.
                <ul>
                  <li>Improve calibration factor estimation.</li>
                  <li>Improve the display of wind curves.</li>
                  <li>Preview the estimated wind curve before applying a new calibration factor.</li>
                  <li>Rename Eta (grec letter) to Mechanical efficiency.</li>
                </ul>
              </li>
              <li>Improve Notio data computation.</li>
            </ul>
          </Panel>

          <Panel title='V.1.13.13 - released January 30th 2020' default_active={false}>
            <p>This version fix bugs and improve stability.</p>

            <p><strong>Features:</strong></p>

            <ul>
              <li>Add "Correct BCVx speed drops" data processor.</li>
            </ul>

            <p><strong>Improvements:</strong></p>

            <ul>
              <li>Improve Reorder charts window.
                <ul>
                  <li>Allow drag and drop to change charts order.</li>
                  <li>Add the possibility to move multiple contiguous items together</li>
                  <li>Enable usage of up and down arrow from keyboard to move around charts.</li>
                </ul>
              </li>

              <li>Improve "Correct BCVx power drops" data processor.</li>
              <li>Update Roll graph descriptions and units. (Need to reset chart layout or create a new user to see changes)</li>

              <li>Improve CdA Analysis chart.
                <ul>
                  <li>Update UI text and error messages.</li>
                  <li>Rename "Coefficient 1" to "Calibration Factor".</li>
                  <li>Set the maximum CdA value displayed to 1.575 and a minimum to -1.575.</li>
                </ul>
              </li>
            </ul>
          </Panel>

          <Panel title='V.1.13.12 - released October 8th 2019' default_active={false}>
            <p><strong>Fixed Bugs:</strong></p>
            <ul>
              <li>Fix previous window state loss when launching software. Themes and sidebar configuration are remembered between sessions.</li>
              <li>Fix an issue always asking for which athlete profile to open at GC start. (Windows)</li>
            </ul>

            <p><strong>Improvements:</strong></p>

            <ul>
              <li>Improve stability.</li>
              <li>Update default charts layout. User need to reset layout from View menu to see changes.</li>
              <li>Add the action to reload the 3D map.</li>
              <li>Users can now compute from the Notio CdA Analysis chart.</li>
              <li>Add a button to compute CdA on the summary chart.</li>
              <li>Add manual check for updates in the About menu.</li>
              <li>Improve files listing and download from Notio cloud.</li>
            </ul>
          </Panel>

          <Panel title='V.1.13.0 - released August 18th 2019' default_active ={false}>
            <div className='important'>
              <p><strong>Important</strong></p>

              <p>Before installing this version, make sure to do a backup of your athletes library first. Then, uninstall the old version of the software.</p>

              <p>You could restore your library afterward in the new default library folder or change in the preferences (options) of GCNotio the folder where you want your library to be.</p>

              <p><strong>Windows Users</strong></p>

              <p>The default athletes directory will be different. Be sure to copy the old folder content into the new.</p>

              <p>
                Old default folder: C:\Users\{'{'}put your username{'}'}\AppData\Local\GoldenCheetahNotioKonect<br />
                New default folder: C:\Users\{'{'}put your username{'}'}\AppData\Local\GCNotio
              </p>

              <p><strong>MacOS Users</strong></p>

              <p>
                No specific actions is required. <br />
                Default folder: /Users/{'{'}put your username{'}'}/Library/GoldenCheetah
              </p>
            </div>
            <br />

            <p><strong>Fixed Bugs (MacOS):</strong></p>

            <ul>
              <li>Fix file importation Finder window hanging in some occasions.</li>
            </ul>

            <p><strong>New features:</strong></p>

            <ul>
              <li>Rename the software "GCNotio".</li>
              <li>Implement access to our new cloud service named simply "Notio". You will need to connect if you are using the new iOS app to see your rides.
                <ul>
                  <li>The coaching feature and Garmin Connect sync are not yet implemented for the new service.</li>
                </ul>
              </li>
              <li>Implement access to our new cloud service named simply "Notio". You will need to connect if you are using the new iOS app to see your rides.</li>
              <li>The coaching feature and Garmin Connect sync are not yet implemented.</li>
              <li>Rename our old cloud to “Legacy Notio Konect”. You still have access if your are using the old iOS app. Only the name changed.</li>
              <li>The default blank page welcoming new athletes have been customized for Notio to improve user experience.</li>
            </ul>

            <p><strong>Improvements:</strong></p>

            <ul>
              <li>Remove the word "Konect" from user interface, except for the old cloud service.</li>
              <li>Improve UI and algorithms of Notio BCVx data processors.</li>
              <li>Implement “auto detect offset” feature to Data processor "Correct BCVx using Garmin".</li>
              <li>Improve Notio Altitude Tool. Compute ride when setting is changed by the user.</li>

              <li>CdA Analysis Chart
                <ul>
                  <li>Automatically compute and save data when estimating coefficient 1.</li>
                  <li>Improve zooming on the plot.</li>
                  <li>Modify curve hovering tooltip time format and Y value precision.</li>
                  <li>Fix standard DataSeries loss when saving parameters.</li>
                  <li>Improve parameter fields below the graph.</li>
                </ul>
              </li>

              <li>3D Map overhaul
                <ul>
                  <li>Add standard DataSeries metrics to be displayed on the map.</li>
                  <li>Add user defined Data series in the chart settings.</li>
                  <li>Improve 3D wall display.</li>
                  <li>Add setting to change wall sections duration from 15 to 60 seconds.</li>
                  <li>Color bar have now default colors and zones definition. The user can still modify the parameters.</li>
                  <li>Improve small plot appearance.</li>
                  <li>Modify curve hovering tooltip time format and Y value precision on the small plot.</li>
                </ul>
              </li>
            </ul>
          </Panel>
        </Accordion>
      </ReleaseNotes>
    </React.Fragment>
  );
}

export default Download;
