import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as routes from 'constants/routes';
import ProtectedRoute from 'components/ProtectedRoute';

import Page404 from 'views/404';

import SignIn from 'views/SignIn';
import SignUp from 'views/SignUp';
import PasswordForgot from 'views/PasswordForgot';
import PasswordReset from 'views/PasswordReset';

import Account from 'views/Account';

import Firebase, { FirebaseContext } from 'components/Firebase';

const App: React.FC = () => {
  return (
    <FirebaseContext.Provider value={Firebase()}>
      <BrowserRouter>
        <Switch>

          <Route exact path='/' component={SignIn} />
          <Route path={routes.SIGN_IN} component={SignIn} />
          <Route path={routes.SIGN_UP} component={SignUp} />

          <Route path={routes.PASSWORD_FORGOT} component={PasswordForgot} />
          <Route path={routes.PASSWORD_RESET} component={PasswordReset} />

          <ProtectedRoute
            path={routes.ACCOUNT}
            component={Account}
            redirectTo={routes.SIGN_IN}
          />

          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </FirebaseContext.Provider>
  );
}

export default App;
