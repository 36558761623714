import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import HeadTitle from 'components/HeadTitle';
import Button from 'components/Button';
import Input, { InputErrorType } from 'components/Input';

import SuccessMsg from 'components/Firebase/Success';
import { FirebaseContext } from 'components/Firebase';

const Form = styled.form``;

const Footer = styled.div`
  margin-top: 1.5em;
`;

const emptyError: InputErrorType = {
  code: '',
  message: ''
};

const AccountPassword: React.FC = () => {
  const firebase = useContext(FirebaseContext);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [error, setError] = useState(emptyError);
  const [success, setSuccess] = useState(false);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newPassword !== newPasswordConfirm) {
      setError({
        code: 'confirm-password',
        message: 'Passwords must match!'
      });
    } else {
      setError(emptyError);

      firebase.reauthenticate(oldPassword)
        .then(() => {
          firebase.updatePassword(newPassword)
            .then(() => {
              setOldPassword('');
              setNewPassword('');
              setNewPasswordConfirm('');
              setSuccess(true);
            })
            .catch((error: InputErrorType) => {
              setError(error);
            })
        })
        .catch((error: InputErrorType) => {
          setError(error);
        });
    }
  };

  return (
    <React.Fragment>
      <HeadTitle>Password</HeadTitle>
      <Form onSubmit={onSubmit}>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
          <Input
            type='password'
            placeholder='Old Password'
            required
            value={oldPassword}
            error={error}
            errorCodes={['auth/wrong-password']}
            onChange={e => setOldPassword(e.currentTarget.value)}
          />
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Input
              type='password'
              placeholder='New Password'
              required
              value={newPassword}
              error={error}
              hint={error.code !== 'auth/weak-password' ? 'Password should be at least 6 characters.' : ''}
              errorCodes={['auth/weak-password']}
              onChange={e => setNewPassword(e.currentTarget.value)}
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Input
              type='password'
              placeholder='Confirm New Password'
              required
              value={newPasswordConfirm}
              error={error}
              errorCodes={['auth/weak-password', 'confirm-password']}
              onChange={e => setNewPasswordConfirm(e.currentTarget.value)}
            />
          </div>
          <div className='col-xs-12'>
            {success && (
              <SuccessMsg>You have successfully updated your password!</SuccessMsg>
            )}
          </div>
        </div>

        <Footer className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Button type='submit'>Update</Button>
          </div>
        </Footer>
      </Form>

    </React.Fragment>
  );
}

export default AccountPassword;
