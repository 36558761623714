import React from 'react'

const sprites = (id: string) => {
  switch (id) {
    case 'logo':
      return (
        <svg viewBox="0 0 190 52" aria-labelledby='Notio' xmlns="http://www.w3.org/2000/svg">
          <g fill="currentColor" fillRule="nonzero">
            <path d="M102.567 20.743H91.225v-9.666h33.522v9.666h-11.34v28.72h-10.84v-28.72"/>
            <path d="M130.967 49.464h10.84V11.077h-10.84z"/>
            <path d="M168.427 40.28c-5.53 0-10.01-4.482-10.01-10.01 0-5.53 4.48-10.012 10.01-10.012 5.529 0 10.01 4.482 10.01 10.012 0 5.528-4.481 10.01-10.01 10.01m0-30.916c-11.545 0-20.905 9.36-20.905 20.906 0 11.544 9.36 20.906 20.905 20.906 11.546 0 20.904-9.362 20.904-20.906 0-11.547-9.358-20.906-20.904-20.906"/>
            <path d="M.64 11.077h10.84l14.973 20.562V11.077h10.84v38.387h-10.84L11.479 28.9v20.563H.64V11.077"/>
            <path d="M47.602 25.562c-2.708 11.162 4.146 22.406 15.307 25.113 11.161 2.708 22.404-4.146 25.111-15.309 2.707-11.162-4.146-22.404-15.307-25.111-1.647-.4-3.296-.59-4.919-.59-9.375-.001-17.885 6.382-20.192 15.897m17.862 14.58c-5.345-1.298-8.627-6.681-7.33-12.025 1.296-5.345 6.68-8.627 12.024-7.33 5.344 1.296 8.626 6.68 7.33 12.024-1.105 4.556-5.18 7.613-9.67 7.613-.776 0-1.565-.091-2.354-.282"/>
            <path d="M58.604 1.666c-4.17 0-7.93 1.29-10.874 3.731-8.473 7.029-7.963 21.67 1.135 32.639 5.65 6.814 13.498 10.881 20.995 10.881 4.168 0 7.927-1.29 10.871-3.731 8.473-7.03 7.964-21.672-1.134-32.64-5.651-6.813-13.5-10.88-20.993-10.88zm11.256 48.17c-7.767 0-15.88-4.192-21.702-11.213-9.422-11.359-9.877-26.581-1.014-33.933C50.254 2.11 54.217.747 58.6.747c7.767 0 15.88 4.191 21.703 11.211 9.421 11.36 9.876 26.582 1.014 33.935-3.111 2.58-7.073 3.943-11.458 3.943z"/>
            <path d="M67.499 3.366c-1.343 0-2.69.166-4.005.493-10.499 2.61-16.885 14.9-14.236 27.398 1.64 7.748 6.423 14.062 12.796 16.89 2.265 1.005 4.62 1.514 7.002 1.514 1.342 0 2.69-.165 4.004-.492 10.5-2.61 16.887-14.902 14.238-27.4-1.641-7.747-6.425-14.06-12.795-16.887l-.118-.052c-2.23-.971-4.546-1.464-6.886-1.464zm1.557 47.217c-2.51 0-4.992-.537-7.375-1.595-6.642-2.947-11.623-9.504-13.324-17.54-2.75-12.978 3.94-25.755 14.915-28.483 1.387-.345 2.81-.52 4.227-.52 2.51 0 4.992.537 7.376 1.595l.08.036c6.6 2.962 11.548 9.498 13.244 17.503 2.75 12.977-3.941 25.755-14.917 28.484-1.387.345-2.809.52-4.226.52z"/>
            <path d="M43.008 23.182c-3.121 10.473 4.069 22.02 16.028 25.741 1.7.53 3.445.883 5.186 1.052 10.408 1.004 19.516-4.459 22.147-13.293 3.12-10.473-4.07-22.02-16.028-25.74a25.918 25.918 0 0 0-5.127-1.046h-.002l-.059-.006C54.746 8.887 45.64 14.35 43.008 23.182zm23.666 27.815c-.836 0-1.683-.04-2.539-.123a26.807 26.807 0 0 1-5.368-1.089C46.34 45.92 38.881 33.868 42.142 22.924 44.9 13.672 54.4 7.943 65.24 8.992l-.043.446v.006l.045-.453c1.8.175 3.606.54 5.367 1.088 12.429 3.867 19.886 15.917 16.625 26.861-2.539 8.523-10.798 14.057-20.56 14.057z"/>
            <path d="M45.903 13.31c-2.96 5.464-1.787 13.605 2.919 20.26 3.722 5.263 9.52 9.534 15.508 11.422 2.23.704 4.433 1.06 6.545 1.06 2.664 0 5.153-.567 7.396-1.686 4.946-2.467 7.75-6.757 7.897-12.08.113-4.123-1.402-8.666-4.264-12.794-2.98-4.297-7.187-7.892-12.166-10.397C66.1 7.265 62.49 6.337 59.01 6.337c-5.755 0-10.778 2.672-13.107 6.972zm24.972 33.686c-2.209 0-4.506-.372-6.829-1.105-6.178-1.948-12.157-6.35-15.994-11.778-4.79-6.772-6.045-15.069-3.198-20.83l-.008-.004.227-.419.005-.007.218-.403.007.004c2.583-4.367 7.787-7.06 13.707-7.06 3.63 0 7.382.962 11.152 2.858 5.119 2.576 9.447 6.277 12.517 10.703 2.976 4.292 4.55 9.035 4.431 13.356-.156 5.689-3.146 10.27-8.418 12.898-2.375 1.186-5.006 1.787-7.817 1.787z"/>
          </g>
        </svg>
      )
    case 'hamburger':
      return (
        <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.44 23h21.042M3 13.5h11M3.44 5h21.042" stroke="currentColor" strokeWidth="3" fill="none" fillRule="evenodd" strokeLinecap="square"/>
        </svg>
      )
    case 'back':
      return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <path d="M28 14H8.8l4.62-4.62c.394-.394.58-.864.58-1.38 0-.984-.813-2-2-2-.531 0-.994.193-1.38.58l-7.958 7.958C2.334 14.866 2 15.271 2 16s.279 1.08.646 1.447l7.974 7.973c.386.387.849.58 1.38.58 1.188 0 2-1.016 2-2 0-.516-.186-.986-.58-1.38L8.8 18H28a2 2 0 000-4z" fill="currentColor"/>
        </svg>
      )
    case 'download':
      return (
        <svg viewBox="0 0 23 30" xmlns="http://www.w3.org/2000/svg">
          <g strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round">
            <path d="M.75 28.47V8.15m20.79 20.51H.75M21.537.75H7.546L.8 8.03M21.54 1v27.46M7.53 1.02V7.9M.8 8.05h6.72" stroke="currentColor"/>
            <path d="M9 22h5.5" stroke="currentColor"/>
            <path d="M11.788 18.1V9.697m0 8.403l-4.2-4.201m4.2 4.201l4.2-4.201" stroke="currentColor"/>
          </g>
        </svg>
      )
    case 'signout':
      return (
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.308 0A.68.68 0 019 .667a.68.68 0 01-.692.666H2.077A.68.68 0 001.385 2v12c0 .368.31.667.692.667h6.23a.68.68 0 01.693.666.68.68 0 01-.692.667H2.077C.932 16 0 15.103 0 14V2C0 .897.932 0 2.077 0zm2.362 3.213a.663.663 0 01.967-.007l4.16 4.285A.73.73 0 0116 8a.732.732 0 01-.204.509l-4.16 4.285a.666.666 0 01-.966-.007.737.737 0 01.007-1.01l2.972-3.063H5.684C5.306 8.714 5 8.395 5 8c0-.395.306-.714.684-.714h7.965l-2.972-3.063a.736.736 0 01-.007-1.01z" fill="currentColor"/>
        </svg>
      )
    case 'arrow-down':
      return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 22a2 2 0 01-1.41-.59l-10-10a2 2 0 012.82-2.82L16 17.17l8.59-8.58a2 2 0 012.82 2.82l-10 10A2 2 0 0116 22z" fill="currentColor"/>
        </svg>
      )
    default:
      break
  }
}

type InlineSVGType = {
  className?: string;
  icon: string;
  onClick?: () => void
}

const InlineSVG = (props: InlineSVGType) => {
  const classname = props.className || '';
  const cls = 'icon icon--' + props.icon + ' ' + classname;

  return (
    <span className={cls} onClick={props.onClick}>
      { sprites(props.icon) }
    </span>
  )
}

export default InlineSVG
