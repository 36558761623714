import React, { useContext } from 'react';
import Page from 'components/Page';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import * as routes from 'constants/routes';
import * as mq from 'constants/mediaQueries';
import colors from 'constants/colors';
import InlineSVG from 'components/InlineSVG';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FirebaseContext } from 'components/Firebase';

import Rides from './Rides';
import Password from './Password';
import Purchases from './Purchases';
import Settings from './Settings';
import Download from './Download';

const Title = styled.h1`
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 22px;

  ${mq.from(mq.device.tablet)} {
    margin-top: 3em;
    font-size: 28px;
  }
`;

const Wrap = styled.div`
  display: flex;
`;

const Sidebar = styled.div`
  width: 200px;
  margin-right: 2em;
  padding-top: 30px;
  border-top: 4px solid ${colors.yellow};
  flex-shrink: 0;

  ${mq.from(mq.device.ipad)} {
    width: 300px;
  }

  ${mq.to(mq.device.tablet)} {
    display: none;
  }
`;

const SidebarTitle = styled.h2`
  margin-top: 0;
  font-size: 26px;
  text-transform: uppercase;
`;

const Link = styled(NavLink)`
  display: block;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: currentColor;
  text-decoration: none;

  &.active {
    font-weight: 700;
  }
`;

const Footer = styled.footer`
  margin-top: 30px;
  padding: 30px 0 2em;
  border-top: 4px solid ${colors.yellow};
`;

const SignOut = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: underline;

  svg {
    display: block;
    width: 1em;
    height: 1em;
    margin-right: 1em;
    margin-top: -1px;
  }
`;

const Content = styled.div`
  padding-top: 30px;
  border-top: 4px solid ${colors.yellow};
  flex-grow: 1;
`;

const Account: React.FC = () => {
  const firebase = useContext(FirebaseContext);
  const user = firebase.user.user;
  const name = user.displayName ? user.displayName : user.email;

  return (
    <Page>
      <div className='container-fluid'>
        <Title>{`Welcome ${name}!`}</Title>

        <Wrap>
          <Sidebar>
            <SidebarTitle>My Account</SidebarTitle>

            <Link to={routes.ACCOUNT_SETTINGS}>Account Settings</Link>
            <Link to={routes.ACCOUNT_PASSWORD}>Password</Link>
            <Link to={routes.ACCOUNT_RIDES}>My Rides</Link>
            <Link to={routes.ACCOUNT_PURCHASES}>My Purchases</Link>
            <Link to={routes.APP_DOWNLOAD}>Download Golden Cheetah</Link>

            <Footer>
              <SignOut onClick={() => firebase.signOut()}>
                <InlineSVG icon='signout' />
                Sign Out
              </SignOut>
            </Footer>
          </Sidebar>

          <Content>
            <Switch>
              <Route path={routes.ACCOUNT_RIDES} component={Rides} />
              <Route path={routes.ACCOUNT_SETTINGS} component={Settings} />
              <Route path={routes.ACCOUNT_PASSWORD} component={Password} />
              <Route path={routes.ACCOUNT_PURCHASES} component={Purchases} />
              <Route path={routes.APP_DOWNLOAD} component={Download} />

              <Route component={() => <Redirect to={routes.ACCOUNT_RIDES} />} />
            </Switch>
          </Content>
        </Wrap>

      </div>
    </Page>
  );
}

export default Account;
