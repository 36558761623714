import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  children: string
}

const HeadTitle = (props: Props) => {
  return (
    <Helmet>
      <title>{props.children}</title>
    </Helmet>
  )
}

export default HeadTitle;
