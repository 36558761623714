import React from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';

const Wrap = styled.div`
  margin-top: 2.5em;
  text-align: center;
`;

interface PageLinkProps {
  active: boolean,
}

const PageLink = styled.button<PageLinkProps>`
  padding: 0.7em 0.8em;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${colors.lightestGray};
  }

  ${props => props.active && `
    font-weight: 700;
  `}
`;

type Props = {
  rides: Array<any>;
  page: number;
  setPage: (page: number) => void;
  wrapperReference: React.RefObject<HTMLDivElement>
}

const Pager = (props: Props) => {
  const rides = props.rides;
  const wrapper = props.wrapperReference.current;

  // Don't render pager if there's only one page
  if (rides.length <= 10) {
    return null;
  }

  const totalPages = Math.ceil(rides.length / 10);
  const pageLinks: Array<React.ReactElement> = [];

  const onPageClick = (index: number) => {
    if (wrapper) {
      wrapper.scrollIntoView({behavior: 'smooth'});
    }

    props.setPage(index);
  }

  for (let i = 0; i < totalPages; i++) {
    pageLinks.push((
      <PageLink key={i} active={props.page === i} onClick={() => onPageClick(i)}>
        {i + 1}
      </PageLink>
    ))
  }

  return (
    <Wrap>
      {pageLinks}
    </Wrap>
  )
}

export default Pager;
