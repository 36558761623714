import React, { useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import colors from 'constants/colors';
import Button from 'components/Button';
import InlineSVG from 'components/InlineSVG';
import Pager from './Pager';
import axios from 'axios';
import FileSaver from 'file-saver';
import { Session } from 'utils';
import { SanitizedDataType } from 'views/Account/Rides';
import * as mq from 'constants/mediaQueries';

const FilterHeader = styled.header`
  display: flex;
  margin-top: -1em;
  margin-bottom: 2em;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 14px;

  ${mq.to(mq.device.mobileL)} {
    flex-direction: column;
  }
`;

const Filters = styled.div`
  display: flex;
  align-items: center;

  ${mq.to(mq.device.mobileL)} {
    flex-wrap: wrap;
    justify-content: center;

    span {
      width: 100%;
      margin-top: 1.5em;
      margin-bottom: 0.5em;
      text-align: center;
    }
  }

  span {
    font-weight: 600;
    white-space: nowrap;
  }
`;

const Filter = styled(Button)`
  margin-left: 7px;
  margin-right: 7px;
  min-width: 100px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  outline: none;
  border-radius: 3em;
  border: 2px solid;
  color: ${colors.gray};
  background-color: transparent;

  ${mq.to(mq.device.tablet)} {
    width: auto;
  }

  ${mq.between(mq.device.mobileL, mq.device.tablet)} {
    &:nth-child(2n) {
      margin-left: 15px;
    }
  }

  ${mq.from(mq.device.tablet)} {
    margin-right: 0;
    margin-left: 15px;
  }

  &:hover {
    color: ${colors.black};
    background-color: transparent;
  }

  ${props => props.active && `

    &,
    &:hover {
      color: ${colors.white};
      background-color: ${colors.gray};
      border-color: ${colors.gray};
    }
  `}
`;

const RideMonthTitle = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.22px;
  line-height: 1.375;

  &:not(:first-child) {
    margin-top: 3em;
  }
`;

const RidesWrap = styled.div``;

const download = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(46px);
  }
`;

const RideBlock = styled.div<any>`
  position: relative;
  margin-bottom: 10px;
  padding: 1em 1.25em;
  background-color: ${colors.lightestGray};
  z-index: 1;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -50px;
    right: 0;
    bottom: 0;
    background: repeating-linear-gradient(-55deg, ${colors.lightGray} 1px, ${colors.lightestGray} 2px, ${colors.lightestGray} 11px, ${colors.lightGray} 12px, ${colors.lightGray} 20px);
    animation: ${download} 0.5s linear infinite;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  ${props => props.downloading && `
    &::before {
      opacity: 0.1;
      visibility: visible;
    }
  `}
`;

const Title = styled.h2`
  margin: 0 0 0.8em;
  font-size: 14px;
  font-weight: 600;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  ${mq.between(mq.device.tablet, 820)} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${mq.to(mq.device.tabletS)} {
    flex-direction: column;
  }
`;

const DataWrap = styled.div`
  ${mq.to(mq.device.tabletS)} {
    display: flex;
    width: 100%;
    margin-bottom: 1em;
    justify-content: space-between;
  }
`;

const Data = styled.span`
  display: inline-block;
  padding-right: 1em;
  min-width: 7.5em;

  ${mq.between(mq.device.tabletS, mq.device.ipad)} {
    &:nth-child(3n),
    &:nth-child(2n) {
      min-width: 6em;
    }
  }

  ${mq.from(mq.device.ipad)} {
    min-width: 8em;
  }

  ${mq.to(mq.device.tabletS)} {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    min-width: 0;
  }
`;

const DownloadWrap = styled.div`
  display: flex;

  ${mq.between(mq.device.tablet, 820)} {
    margin-top: 1.5em;
  }

  ${mq.to(mq.device.tabletS)} {
    margin-right: auto;
  }
`;

const DownloadLink = styled.span`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: currentColor;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 3em;

  svg {
    display: block;
    width: 15px;
    height: 19px;
    margin-right: 0.7em;
  }

  ${mq.to(mq.device.tabletS)} {
    &:first-child {
      margin-left: 0;
    }
  }

  ${mq.between(mq.device.tablet, 820)} {
    &:first-child {
      margin-left: 0;
    }
  }
`;

const Loader = styled.div`
  margin-top: 2em;
  text-align: center;
  color: ${colors.lightGray};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.span`
  display: inline-block;
  widtH: 4em;
  height: 4em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  border-radius: 50%;
  border: 6px solid transparent;
  border-left-color: #ddd;
  border-right-color: #ddd;
  animation: ${rotate} 1s linear infinite;
`;

const LoaderText = styled.h2`
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
`;

type RidesProps = {
  rides: Array<SanitizedDataType>;
}
console.log('process.env.REACT_APP_NOTIO_API_URL');
console.log(process.env.REACT_APP_NOTIO_API_URL);
console.log('process.env.REACT_APP_ENV');
console.log(process.env.REACT_APP_ENV);

const Rides = (props: RidesProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [sortBy, setSortBy] = useState('date');
  const [downloading, setDownloading] = useState('');
  const [page, setPage] = useState(0);

  const sliceStart = page === 0 ? 0 : page * 10;
  const sliceEnd = page === 0 ? 10 : (page + 1) * 10;

  const downloadFile = (filetype: string, id: string) => {
    // Early exit if something is already being downloaded
    if (downloading !== '') {
      return;
    }

    setDownloading(id);
    const token = Session.getToken();

    axios.get(`${process.env.REACT_APP_NOTIO_API_URL}/activity/v1/activities/data/${id}`, {
      headers: {
        'Accept': filetype === 'csv'
          ? 'application/notio-csv+text'
          : 'application/notio-gc+json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        // console.log(response);
        let data = response.data;
        const type = response.headers['content-type'];

        if (filetype === 'json') {
          data = JSON.stringify(response.data);
        }

        const blobData = new Blob([data], { type: type });
        FileSaver.saveAs(blobData, `notio-ride-${id}.${filetype}`);

        setDownloading('');
      })
      .catch(error => {
        console.log('error fetching file', error);
        setDownloading('');
      })
  }

  // Sort rides data:
  // - by title, A - Z
  // - by date, newest to oldest
  const rides = sortBy === 'title'
    ? props.rides.sort((a: any, b: any) => {
      var titleA = a.title.toUpperCase();
      var titleB = b.title.toUpperCase();

      if (titleA < titleB) {
        return -1;
      }

      if (titleA > titleB) {
        return 1;
      }

      return 0;
    })
    : props.rides.sort((a: any, b: any) => b.date.timestamp - a.date.timestamp);

  const showingGroups: Array<string> = [];

  const rideBlocks = rides.slice(sliceStart, sliceEnd).map(ride => {
    let title = null;

    if (sortBy === 'date') {
      if (showingGroups.indexOf(ride.group) === -1) {
        showingGroups.push(ride.group);
        title = (
          <RideMonthTitle>{ride.group}</RideMonthTitle>
        )
      }
    }

    return (
      <React.Fragment key={ride.id}>
        {title}
        <RideBlock downloading={downloading === ride.id}>
          <Title>{ride.title}</Title>
          <Footer>
            <DataWrap>
              <Data>{ride.date.formatted}</Data>
              <Data>{ride.distance} km</Data>
              <Data>{ride.duration}</Data>
            </DataWrap>
            <DownloadWrap>
              <DownloadLink onClick={() => downloadFile('json', ride.id)}>
                <InlineSVG icon='download' /> JSON
              </DownloadLink>
              <DownloadLink onClick={() => downloadFile('csv', ride.id)}>
                <InlineSVG icon='download' /> CSV
              </DownloadLink>
            </DownloadWrap>
          </Footer>
        </RideBlock>
      </React.Fragment>
    )
  });

  if (!props.rides.length) {
    return (
      <Loader>
        <Spinner />
        <LoaderText>Retrieving your rides...</LoaderText>
      </Loader>
    )
  }

  return (
    <div ref={wrapperRef}>
      <FilterHeader>
        <span>{rides.length} rides</span>
        <Filters>
          <span>Sort by</span>
          <Filter
            onClick={() => setSortBy('title')}
            active={sortBy === 'title'}
          >
            Title
          </Filter>
          <Filter
            onClick={() => setSortBy('date')}
            active={sortBy === 'date'}
          >
            Date
          </Filter>
        </Filters>
      </FilterHeader>

      <RidesWrap>
        {rideBlocks}

        <Pager
          rides={rides}
          page={page}
          setPage={setPage}
          wrapperReference={wrapperRef}
        />
      </RidesWrap>
    </div>
  )
}

export default Rides;
