import React, { useEffect, useState } from 'react';
import { Session } from 'utils';
import firebase from 'firebase/app';
import 'firebase/auth';

// Export context, to be used by the App wrapper
export const FirebaseContext = React.createContext(null as any);

// Firebase project config
// TODO: Potentially move to .env?
const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID
};

// Init Firebase app
firebase.initializeApp(config);
const auth = firebase.auth;

type UserData = {
  user: object,
  token: string
}

const initialUserData: UserData = {
  user: Session.getUser(),
  token: Session.getToken()
}

const Firebase = () => {
  const [userData, setUserData] = useState(initialUserData);
  const { user } = userData;

  useEffect(() => {
    // Add onAuthStateChanged to get the currently logged in user
    auth().onAuthStateChanged(authUser => {
      if (authUser !== user) {
        if (authUser) {
          authUser.getIdToken(true).then((idToken: string) => {
            const compiled = {
              user: authUser,
              token: idToken
            }
            sessionStorage.setItem('authUser', JSON.stringify(compiled));
            setUserData(compiled);
          });
        } else {
          if (authUser !== null || Object.keys(user).length) {
            sessionStorage.removeItem('authUser');
            setUserData({
              user: {},
              token: ''
            });
          }
        }
      }
    });

  }, [user]);

  // Get Firebase Email Auth credential
  const getCredential = (password: string) => {
    const user = auth().currentUser as any;
    const email = user.email;
    const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password
    );

    return credential;
  }

  // Reathenticate with a credential
  const reauthenticate = (password: string) => {
    const credential = getCredential(password);
    const user = auth().currentUser as any;
    return user.reauthenticateWithCredential(credential);
  }

  return {
    user: userData,
    signIn: (email: string, password: string) => auth().signInWithEmailAndPassword(email, password),
    signUp: (email: string, password: string) => auth().createUserWithEmailAndPassword(email, password),
    forgotPassword: (email: string) => auth().sendPasswordResetEmail(email),
    signOut: () => auth().signOut(),
    getCredential: getCredential,
    reauthenticate: reauthenticate,
    updatePassword: (newPassword: string) => {
      const user = auth().currentUser as any;
      return user.updatePassword(newPassword);
    },
    updateName: (name: string) => {
      const user = auth().currentUser as any;
      return user.updateProfile({
        displayName: name
      });
    },
    updateEmail: (email: string) => {
      const user = auth().currentUser as any;
      return user.updateEmail(email);
    }
  };
}

export default Firebase;
