import React from 'react';
import styled, { css }from 'styled-components';
import colors from 'constants/colors';
import { Link } from 'react-router-dom';

const styles = css`
  display: inline-block;
  width: 100%;
  margin: 0.5em 0;
  padding: 0.75em 1.5em;
  border: 3px solid transparent;
  box-shadow: none;
  background-color: ${colors.black};
  border-radius: 3em;
  font-size: 1em;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  color: ${colors.yellow};
  text-decoration: none;
  transition: all 0.15s ease;
  outline: none;

  &:hover {
    box-shadow: none;
    color: ${colors.btn_hover_text};
    background-color: ${colors.darkerGray};
  }
`;

const StyledLink = styled(Link)`
  ${styles};
`;

const StyledExternal = styled.a`
  ${styles};
`;

const StyledButton = styled.button`
  ${styles};
`;

const Button = (props: any) => {
  if (props.to) {
    return (
      <StyledLink {...props}>{props.children}</StyledLink>
    )
  }

  if (props.href) {
    return (
      <StyledExternal
        target='_blank'
        rel='noreferrer noopener'
        {...props}
      >
        {props.children}
      </StyledExternal>
    )
  }

  return (
    <StyledButton {...props}>{props.children}</StyledButton>
  );
}

export default Button;
