const sessionData = () => window.sessionStorage.getItem('authUser');

interface UserInterface {
  [key: string]: any;
}

type SessionType = {
  getUser: () => UserInterface;
  getToken: () => string;
}

export const Session: SessionType = {
  getUser: () => {
    const session = sessionData();
    if (session) {
      return JSON.parse(session).user;
    }
    return {};
  },
  getToken: () => {
    const session = sessionData();
    if (session) {
      return JSON.parse(session).token;
    }
    return '';
  }
}
